<template>
  <Loading v-if="isLoading && !widget" />
  <div v-if="widget" class="page-header">
    <h3>Report for "{{ widget.details.name }}"</h3>
    <img :src="widget.settings.reportLogo || '../assets/images/emojise-logo-small.png'" alt="Logo">
  </div>
  <div v-if="widget" class="container" :style="{ 'background-color': widget.settings.bgColour }">
    <div class="top-container">
      <div class="inner-container">
        <div class="page-subtitle">Main Stats 🗝️</div>
        <div class="main-stats">
          <div class="stats"><h3><strong>{{ widget.stats.totalVotes }}</strong></h3><h5>Total Ratings</h5></div>
          <div class="stats average" :title="`Out of ${Object.keys(widget.emojis).length}`" tabindex="0"><h3><strong>{{ averageRating }}</strong></h3><h5>Average Rating</h5></div>
          <div class="stats"><img v-if="mostVoted" :src="mostVoted"><h3 v-else><strong>-</strong></h3><h5>Most Voted</h5></div>
        </div>
      </div>
      <div class="inner-container right">
        <div class="page-subtitle">Ratings Breakdown 🗳️</div>
        <div class="overall-stats">
          <div v-for="emoji in widget.emojis" :key="emoji.value" class="stats"><img :src="emoji.emojiURL"><h4>{{ emoji.votes }}</h4></div>
        </div>
      </div>
    </div>
    <div class="inner-container chart">
      <div class="page-subtitle">Ratings Over Time 📊 📈</div>
      <Charts :widget="widget" :isReport="true" />
    </div>
    <div class="inner-container with-table">
      <div class="page-subtitle">Page Stats 📃</div>
      <div class="info">
        <table v-if="formattedPages && formattedPages.length">
          <thead>
          <tr :style="{ 'background-color': widget.settings.bgColour }">
            <th>Page Title</th>
            <th>Page URL</th>
            <th class="narrow">Total Votes</th>
            <th class="narrow">Average Rating</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="page in formattedPages" :key="page.id" :style="{ 'background-color': widget.settings.bgColour }">
              <td>{{ page.pageTitle }}</td>
              <td>{{ page.pageURL }}</td>
              <td>{{ page.totalVotes }}</td>
              <td>{{ page.avgRating }}</td>
            </tr>
          </tbody>
        </table>
        <span v-if="!formattedPages || !formattedPages.length">Nothing to show yet 🤷</span>
      </div>
    </div>
    <div class="inner-container with-table last">
      <div class="page-subtitle">Feedback Responses 💬</div>
      <div v-if="widget.stats.followUps > 0" class="select-wrap">
        <select class="filter-select" v-if="widget.settings.votes" v-model="orderBy" :style="{ 'border': `1px solid ${widget.settings.mainColour}`, 'background-color': widget.settings.bgColour }">
          <option value="votes">🔝 Most Voted</option>
          <option value="createdAt">📅 Newest</option>
        </select>
        <select class="filter-select" v-model="filteredStatus" :style="{ 'border': `1px solid ${widget.settings.mainColour}`, 'background-color': widget.settings.bgColour }">
          <option value="any-status">✨ All</option>
          <option value="new">🏷️ New</option>
          <option value="working">🔨 In Progress</option>
          <option value="completed">✔️ Completed</option>
        </select>
      </div>
      <div class="info">
        <table v-if="formattedRatings && formattedRatings.length" class="responses-table">
          <thead>
          <tr :style="{ 'background-color': widget.settings.bgColour }">
            <th v-if="widget.settings.votes"  class="narrow">Votes</th>
            <th class="narrow">Reaction</th>
            <th>Response</th>
            <th>Date & Time</th>
            <th class="narrow">Device</th>
            <th class="narrow">Country</th>
            <th>Page</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="rating in formattedRatings" :key="rating.id" :style="{ 'background-color': widget.settings.bgColour }">
              <td v-if="widget.settings.votes">{{ rating.votes }}</td>
              <td>
                <img :src="rating.emojiURL">
              </td>
              <td class="followup-text">{{ rating.response }}</td>
              <td>{{ rating.date }}</td>
              <td>{{ rating.deviceIcon }}</td>
              <td>{{ rating.countryFlag }}</td>
              <td>{{ rating.page }}</td>
            </tr>
          </tbody>
        </table>
        <button v-if="!isLoading && formattedRatings && formattedRatings.length && formattedRatings.length < totalRatings" @click="fetchRatings" style="margin-top: 20px;">Load More 👇</button>
        <span v-if="isLoading"><br>Fetching Responses... ⏳</span>
        <span v-if="!isLoading && (!formattedRatings || !formattedRatings.length)">No responses to show yet 🤷</span>
      </div>
    </div>
  </div>
  <div v-if="!isLoading && error && !widget" class="container">
    <div class="inner-container not-found">
      <h2>Uh-oh... Report Not Found</h2>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import useFetch from '../composables/useFetch'
import Charts from '../components/Overview/Charts.vue'
import Loading from '../components/Main/Loading.vue'

export default {
  props: ['id'],
  components: { Charts, Loading },
  setup(props) {
    const { getData, error, isLoading } = useFetch()
    const widgetId = props.id.split('-')[0]
    const reportId = props.id.split('-')[1]
    const widget = ref(null)
    const pages = ref(null)
    const ratings = ref([])
    const orderBy = ref('votes')
    const filteredStatus = ref('any-status')

    const averageRating = computed(() => {
      if (widget.value) {
        let totalVotes = widget.value.stats.totalVotes, totalMultiplied = 0

        if (totalVotes > 0) {
          Object.entries(widget.value.emojis).forEach(([key, value]) => {
            totalMultiplied += value.votes * value.value
          });

          return Math.round((totalMultiplied / totalVotes) * 1e2) / 1e2;
        } else {
          return 0
        }
      }
    })

    const mostVoted = computed(() => {
      if (widget.value) {
        const emojiObj = widget.value.emojis;
        let valuesArr = [];

        Object.entries(emojiObj).forEach(([key, value]) => {
          valuesArr.push(value.votes);
        });

        let maxValue = Math.max.apply(Math, valuesArr)

        if (maxValue > 0) {
          const value = Object.values(emojiObj).find(value => value.votes === maxValue);
          return value.emojiURL
        } else {
          return null
        }
      } 
    })

    const formattedPages = computed(() => {
      if (widget.value && pages.value) {
        return pages.value.map(el => {
          const url = (el.pageURL !== 'Email') ? `//${el.pageURL}` : 'Email'
          const title = (el.pageURL !== 'Email') ? `📄 ${el.pageTitle}` : '✉️ Email'
          const avgRating = Math.round((el.accVotes / el.totalVotes) * 1e2) / 1e2

          return { ...el, pageTitle: title, pageHREF: url, avgRating: avgRating }
        })
      }
    })

    const formattedRatings = computed(() => {
      if (widget.value && ratings.value.length) {
        return ratings.value.map(el => {
          return { 
            ...el,
            emojiURL: Object.values(widget.value.emojis).find(value => value.value == el.value).emojiURL,
            deviceIcon: (el.device === 'Mobile' || el.device === 'Tablet' || el.device === 'Phone') ? `📱 ${el.device}` : `🖥️ ${el.device}`,
            countryFlag: `${el.countryCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397))} ${el.countryName}`,
            date: new Date((el.createdAt._seconds*1000) + (el.createdAt._nanoseconds/100000)).toLocaleString()
          }
        })
      }
    })

    const totalRatings = computed(() => {
      if (widget.value) {  
        if (filteredStatus.value === 'all-responses') {
          return widget.value.stats.followUps
        } else {
          return widget.value.stats.statusCount[filteredStatus.value]
        }
      }
    })

    const fetchWidget = async () => {
      const data = await getData(
        `/widgets/${widgetId}`,
        `reportId=${reportId}`
      )

      if (!error.value) {
        widget.value = data
      } else {
        widget.value = null
      }
    }

    const fetchPages = async () => {
      const data = await getData(
        `/pages/${widgetId}`,
        `orderBy=totalVotes&limit=null`
      )

      if (!error.value) {
        pages.value = data.documents
      } else {
        pages.value = null
      }
    }

    const fetchRatings = async () => {
      const startAfter = (ratings.value.length > 0) ? ratings.value[ratings.value.length - 1].id : null

      const data = await getData(
        `/feedback/${widgetId}`,
        `orderBy=${orderBy.value}&type=all-responses&status=${filteredStatus.value}&page=null&limit=30&startAfter=${startAfter}`
      )

      if (!error.value) {
        ratings.value = [...ratings.value, ...data.documents]
      } else {
        ratings.value = null
      }
    }

    onMounted(async () => {
      await fetchWidget()

      if (widget.value) {
        if (widget.value.stats.totalVotes > 0) {
          await fetchPages()
          await fetchRatings()

          watch(orderBy, async () => {
            if (widget.value) {
              await fetchRatings()
            }
          })

          watch(filteredStatus, async () => {
            if (widget.value) {
              await fetchRatings()
            }
          })
        }
      }
    })

    return { widget, averageRating, mostVoted, formattedPages, formattedRatings, orderBy, 
      filteredStatus, totalRatings, error, isLoading, fetchRatings }
  }
}
</script>

<style scoped>
  .page-header {
    width: 100%;
    position: sticky;
  }
  .page-header img {
    max-width: 200px;
    max-height: 50px;
    vertical-align: bottom;
  }
  .inner-container {
    position: relative;
  }
  .inner-container.not-found {
    position: absolute !important;
    top: 50% !important;
  }
  .top-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .top-container .inner-container {
    width: 49%;
    min-height: 185px;
    margin-bottom: 30px !important;
  }
  .inner-container.with-table {
    overflow-x: auto;
  }
  .inner-container.last {
    margin-bottom: 0;
  }
  .main-stats, .overall-stats {
    text-align: center;
  }
  .main-stats h3 {
    margin-bottom: 10px;
  }
  .main-stats img {
    width: 35px;
    height: 35px;
  }
  .overall-stats img {
    width: 42px;
    height: 40px;
    margin-bottom: 5px;
  }
  .stats {
    display: inline-block;
    width: 17%;
    padding: 10px 16px;
    text-align: center;
    border-radius: 30px;
    margin: 0 5px;
  }
  .main-stats .stats {
    width: 25%;
    padding: 20px 20px 10px 20px;
  }
  .hidden-chart {
    visibility: hidden;
  }
  .charts {
    text-align: center;
  }
  .select-wrap {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .filter-select {
    height: 27px;
    margin-left: 20px;
    border: 1px solid var(--main);
    border-radius: 7px;
    padding: 0 5px;
    background-color: #fff0f0;
    cursor: pointer;
  }
  .info {
    text-align: center;
    padding-bottom: 10px;
  }
  table {
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
  }
  table tr {
    background-color: #fff0f0;
    border-radius: 5px;
  }
  table th, table td {
    padding: 10px;
    text-align: center;
    word-wrap: break-word;
  }
  table th.narrow {
    width: 150px;
  }
  table img {
    width: 30px;
    height: 30px;
  }
  table.responses-table {
    margin-top: 30px;
  }
  
  @media print {
    .page-header {
      position: unset;
      width: 100%;
      text-align: right !important;
    }
    button {
      display: none !important;
    }
    .top-container .inner-container {
      width: 100%;
      min-height: unset;
    }
    .top-container .inner-container .main-stats .stats {
      padding-top: 0 !important;
    }
    .charts canvas {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
</style>